import { Typography } from '@/core/ui';
import { useTranslation } from 'react-i18next';
import { Icon } from '../icon';

export const ScreenSaver = ({ reset }: { reset: () => void }) => {
  const { t } = useTranslation('translation');

  const handleClick = () => {
    reset();
  };

  return (
    <div className='flex h-screen bg-apple bg-cover bg-objects flex-col justify-center items-center'>
      <Icon name='OBJECT_LOGO_TEXT' className='w-40 absolute top-[11%]' />
      <div className='flex flex-col space-y-8 items-center'>
        <Typography className='font-united-sans text-[100px] font-semibold leading-[95px] uppercase tracking-[7px]'>
          {t('screenSaver.title')}
        </Typography>
        <Typography className='font-united-sans text-[50px] font-light leading-[50px] uppercase tracking-[7.5px]'>
          {t('screenSaver.subtitle')}
        </Typography>
      </div>
      <div className='absolute bottom-[20%] flex justify-center items-center p-6 rounded-full bg-danger/20'>
        <button
          onClick={handleClick}
          type='button'
          className='justify-center items-center flex rounded-full w-20 h-20 bg-danger'
        >
          <Icon name='CHEVRON_RIGHT' />
        </button>
      </div>
    </div>
  );
};
