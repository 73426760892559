import { LANGUAGES } from '@/config';
import { Icon } from '@/core/icon';
import { Typography } from '@/core/ui';
import { Popover, PopoverContent, PopoverTrigger } from '@/core/ui';
import { cn } from '@/lib';
import { useTranslation } from 'react-i18next';

export const Header = () => {
  const { i18n } = useTranslation('translation');

  const changeLanguage = (lng: LANGUAGES) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className='w-full absolute top-0'>
      <div className='flex flex-row justify-between items-center pt-12 px-24'>
        <div className=''>
          <Icon name='HALL_LOGO' className='w-auto h-12' />
        </div>
        <div className='flex flex-row space-x-8'>
          <Popover>
            <PopoverTrigger>
              <div className='h-[72px] w-[72px] bg-danger flex items-center justify-center text-center rounded-full'>
                <Icon name='GLOBE' className='' />
              </div>
            </PopoverTrigger>
            <PopoverContent side='left' className='bg-transparent shadow-none border-none w-full mr-2'>
              <div className='flex flex-row space-x-6'>
                {Object.entries(LANGUAGES).map(([, lng]) => (
                  <button
                    type='button'
                    onClick={() => changeLanguage(lng)}
                    key={lng}
                    className={cn(
                      'h-[72px] w-[72px] flex items-center justify-center',
                      'bg-chalk text-center rounded-full border border-content2',
                      { 'border-danger': lng === i18n.language },
                    )}
                  >
                    <Typography
                      className={cn('text-content2 pt-0.5 font-semibold uppercase', {
                        'text-danger': lng === i18n.language,
                      })}
                    >
                      {lng}
                    </Typography>
                  </button>
                ))}
              </div>
            </PopoverContent>
          </Popover>
        </div>
      </div>
    </div>
  );
};
